import React from 'react';
import { Seo } from '@/components';
import Hero from '@/layouts/RedesFranquias/Hero';
import HowItWorks from '@/layouts/RedesFranquias/HowItWorks/HowItWorks';
import Feedbacks from '@/layouts/RedesFranquias/Feedbacks/Feedbacks';
import Product from '@/layouts/RedesFranquias/Product';
import Features from '@/layouts/RedesFranquias/Features';
import Numbers from '@/layouts/RedesFranquias/Numbers';
import Footer from '@/layouts/RedesFranquias/Footer';
import Testimonial from '@/layouts/RedesFranquias/Testimonial';
import howItWorksConstants from '../layouts/RedesFranquias/HowItWorks/constants';
import Root from '@/layouts/Root/Root';
import { Helmet } from 'react-helmet';

const googleRecaptchaID = process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY;

const RedesFranquias = () => {
  return (
    <>
      <Seo
        title="Software para Redes e Franquias Odontológicas"
        description="Maximize o potencial da sua Rede ou Franquias de Clínicas Odontológicas com o Simples Dental, o software completo para gestão de clínicas. Centralize dados da sua operação e descubra oportunidades em tempo real. Experimente agora!"
      />
      <Helmet>
        <script
          async
          src={`https://www.google.com/recaptcha/api.js?render=${googleRecaptchaID}`}
        ></script>
      </Helmet>
      <Root variant="light" hero={<Hero />}>
        <Testimonial />
        {howItWorksConstants.map(f => (
          <HowItWorks funcionalidade={f} key={f.id} />
        ))}
        <Product />
        <Feedbacks />
        <Features />
        <Numbers />
        <Footer />
      </Root>
    </>
  );
};

export default RedesFranquias;

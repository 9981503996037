import React from 'react';
import { Container } from 'react-bootstrap';

import agendaOnline from '@/assets/icons/agenda-online.svg';
import anamneseDigital from '@/assets/icons/anamnese-digital.svg';
import appPacienteClinica from '@/assets/icons/app-paciente-clinica.svg';
import assinaturaEletronica from '@/assets/icons/assinatura-eletronica.svg';
import confirmacaoConsultas from '@/assets/icons/confirmacao-consultas.svg';
import contrato from '@/assets/icons/contrato.svg';
import contratoDigital from '@/assets/icons/contrato-digital.svg';
import ferramentaOrtodontista from '@/assets/icons/ferramenta-ortodontista.svg';
import funilOrcamento from '@/assets/icons/funil-orcamento.svg';
import gestaoControleFinanceiro from '@/assets/icons/gestao-controle-financeiro.svg';
import hof from '@/assets/icons/hof.svg';
import integracaoWhatsapp from '@/assets/icons/integracao-whatsapp.svg';
import odontograma from '@/assets/icons/odontograma.svg';
import painelInteligencia from '@/assets/icons/painel-inteligencia.svg';
import prontuarioDigital from '@/assets/icons/prontuario-digital.svg';

import * as styles from './features.module.scss';

const Features = () => {
  return (
    <Container>
      <div className={styles.container}>
        <span className={styles.subtitle}>Uma ferramenta completa</span>
        <h2 className={styles.title}>
          Conheça as soluções do Simples Dental para transformar seus resultados
        </h2>
        <h2 className={styles.titleMobile}>
          Conte com as ferramentas que irão lhe ajudar a crescer com o Simples Dental
        </h2>

        <div className={styles.wrapper}>
          <div className={styles.features}>
            <img src={assinaturaEletronica} alt="Assinatura Eletrônica" />
            <p>Assinatura Eletrônica</p>
          </div>
          <div className={styles.features}>
            <img src={contrato} alt="Contrato" />
            <p>Contrato</p>
          </div>

          <div className={styles.features}>
            <img src={integracaoWhatsapp} alt="Integração com Whatsapp" />
            <p>Integração com Whatsapp</p>
          </div>
          <div className={styles.features}>
            <img src={contratoDigital} alt="Contrato Digital" />
            <p>Contrato Digital</p>
          </div>
          <div className={styles.features}>
            <img src={gestaoControleFinanceiro} alt="Gestão e Controle Financeiro" />
            <p>Gestão e Controle Financeiro</p>
          </div>
          <div className={styles.features}>
            <img src={prontuarioDigital} alt="Prontuário Digital" />
            <p>Prontuário Digital</p>
          </div>
          <div className={styles.features}>
            <img src={agendaOnline} alt="Agenda Online" />
            <p>Agenda Online</p>
          </div>
          <div className={styles.features}>
            <img src={painelInteligencia} alt="Painel de Inteligência" />
            <p>Painel de Inteligência</p>
          </div>
          <div className={styles.features}>
            <img src={anamneseDigital} alt="Anamnese Digital" />
            <p>Anamnese Digital</p>
          </div>
          <div className={styles.features}>
            <img src={appPacienteClinica} alt="App Paciente e Clínica" />
            <p>App Paciente e Clínica</p>
          </div>
          <div className={styles.features}>
            <img src={hof} alt="HOF" />
            <p>HOF</p>
          </div>
          <div className={styles.features}>
            <img src={odontograma} alt="Odontograma" />
            <p>Odontograma</p>
          </div>
          <div className={styles.features}>
            <img src={funilOrcamento} alt="Funil de Orçamento e Agendamento" />
            <p>Funil de Orçamento e Agendamento</p>
          </div>
          <div className={styles.features}>
            <img src={ferramentaOrtodontista} alt="Ferramenta para Ortodontista" />
            <p>Ferramenta para Ortodontista</p>
          </div>
          <div className={styles.features}>
            <img src={confirmacaoConsultas} alt="Confirmação de Consultas" />
            <p>Confirmação de Consultas</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Features;

import bruno from '@/assets/images/bruno-ecco.png';
import stanley from '@/assets/images/stanley-lira.png';

export interface Feedback {
  id: number;
  desc: string;
  name: string;
  occupation: string;
  starNumber: number;
  image: string;
}

const feedbacks: Feedback[] = [
  {
    desc: 'Aposentei minhas planilhas e hoje tenho controle total sobre entradas e saídas financeiras.',
    name: 'Bruno Ecco',
    occupation: 'Dentista Administrador',
    starNumber: 5,
    image: bruno,
    id: 1,
  },
  {
    desc: 'O prontuário digital permitiu eliminar todas as fichas de papel, que ocupavam muito espaço na clínica!',
    name: 'Stanley Lira',
    occupation: 'Diretor administrativo',
    starNumber: 5,
    image: stanley,
    id: 2,
  },
];

export default feedbacks;

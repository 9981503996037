import React from 'react';
import Container from 'react-bootstrap/Container';
import * as styles from './product.module.scss';
import { Button } from '@/components';
import solucaoIntegrada from '@/assets/images/solucao-integrada.png';
import check from '@/assets/images/check.svg';
import { trackButtonClick } from '@/services/analytics';
import { buttonEvents, redesEFranquiasButtonNames } from '@/constants/analytics';
import grayTooth from '@/assets/icons/gray-tooth.svg';
import useScrollToTop from '@/utils/useScrollTo';

const Product = () => {
  const scrollToTop = useScrollToTop();
  const handleClick = () => {
    trackButtonClick({
      event: buttonEvents.redesEFranquias,
      buttonName: redesEFranquiasButtonNames.redesEFranquias100SimplesDental,
    });

    scrollToTop();
  };

  return (
    <Container>
      <div className={styles.container}>
        <div className={styles.funcionalidadeImg}>
          <img src={solucaoIntegrada} alt="Solução integrada" />
        </div>

        <div className={styles.funcionalidadeText}>
          <div className={styles.logoWrapper}>
            <img src={grayTooth} alt="Powered by Simples Dental" />
            <span className={styles.desc}> Powered by Simples Dental</span>
          </div>
          <div className={styles.head}>Uma solução 100% integrada ao Simples Dental</div>
          <div className={styles.itensWrapper}>
            <img src={check} alt="Líder em satisfação" />
            <span className={styles.title}>Líder em satisfação</span>
          </div>
          <p className={styles.desc}>
            Aumente a satisfação de seus franqueados e economize tempo de treinamento e adaptação
            com uma ferramenta que simplifica a rotina de todas unidades da sua rede ou franquia.
          </p>
          <div className={styles.itensWrapper}>
            <img src={check} alt="Cadastro ilimitado de profissionais" />
            <span className={styles.title}>Cadastro ilimitado de profissionais</span>
          </div>
          <p className={styles.desc}>
            O Simples Dental cresce com a sua operação! Cadastre quantos usuários quiser sem pagar
            nada mais por isso.
          </p>
          <div className={styles.itensWrapper}>
            <img src={check} alt="Atendimento campeão" />
            <span className={styles.title}>Atendimento campeão</span>
          </div>
          <p className={styles.desc}>
            Temos o melhor índice de satisfação em atendimento do mercado. Converse com pessoas de
            verdade e tenha acesso a materiais educacionais de alta qualidade.
          </p>
          <Button variant="alternative-blue" onClick={() => handleClick()}>
            <span className={styles.text}>Solicite uma demonstração</span> (é grátis!)
          </Button>
        </div>
      </div>
      <hr className={styles.divider} />
    </Container>
  );
};

export default Product;

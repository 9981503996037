import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import * as styles from './how-it-works.module.scss';
import { Funcionalidade as FuncionalidadeInterface } from './constants';
import { Button } from '@/components';
import { trackButtonClick } from '@/services/analytics';
import { buttonEvents } from '@/constants/analytics';
import useScrollToTop from '@/utils/useScrollTo';

export interface FuncionalidadeProps {
  funcionalidade: FuncionalidadeInterface;
  children?: ReactNode;
}

const HowItWorks = ({ funcionalidade, children }: FuncionalidadeProps) => {
  const scrollToTop = useScrollToTop();

  const classes = clsx(styles.container, {
    [styles.left]: funcionalidade.left,
  });

  const handleClick = ({ gaMetadata: { saibaMaisButtonName } }: FuncionalidadeInterface) => {
    if (saibaMaisButtonName) {
      trackButtonClick({
        event: buttonEvents.redesEFranquias,
        buttonName: saibaMaisButtonName,
      });
    }

    scrollToTop();
  };

  return (
    <Container>
      <div className={styles.section}>
        {funcionalidade.sectionSubtitle && (
          <p className={clsx(styles.subtitle)}>{funcionalidade.sectionSubtitle}</p>
        )}
        {funcionalidade.sectionTitle && (
          <h2 className={styles.title}>{funcionalidade.sectionTitle}</h2>
        )}
      </div>
      <div className={classes}>
        <div className={styles.funcionalidadeImg}>
          <img
            src={funcionalidade.image}
            alt={funcionalidade.alt}
            title={funcionalidade.imageTitle}
            key={funcionalidade.id}
          />
        </div>
        <div className={styles.funcionalidadeText}>
          <p className={styles.briefing}>{funcionalidade.subtitle}</p>
          <h2 className={styles.titleItem}>{funcionalidade.title}</h2>
          {children}
          {funcionalidade.desc && <p className={styles.desc}>{funcionalidade.desc}</p>}
          {funcionalidade.showButton && (
            <Button variant="alternative-blue" onClick={() => handleClick(funcionalidade)}>
              <span className={styles.textAlternative}>Solicite uma demonstração</span> (é grátis!)
            </Button>
          )}
        </div>
      </div>

      {funcionalidade.showSecondaryButton && (
        <div className={styles.footerWrapper}>
          <Button variant="alternative-blue" onClick={() => handleClick(funcionalidade)}>
            <span className={styles.textAlternative}>Solicite uma demonstração</span> (é grátis!)
          </Button>
        </div>
      )}

      {funcionalidade.hasHorizontalRule && <hr className={styles.divider} />}
    </Container>
  );
};

export default HowItWorks;

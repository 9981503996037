import React from 'react';
import { Container } from 'react-bootstrap';
import * as styles from './feedbacks.module.scss';
import { Button } from '@/components';
import { trackButtonClick } from '@/services/analytics';
import { buttonEvents, redesEFranquiasButtonNames } from '@/constants/analytics';
import { Feedback as FeedbacksInterface } from './constants';
import feedbackConstants from '@/layouts/RedesFranquias/Feedbacks/constants';
import Feedback from './Feedback';
import useScrollToTop from '@/utils/useScrollTo';

export interface FeedbacksProps {
  feedback: FeedbacksInterface;
}

const Feedbacks = () => {
  const scrollToTop = useScrollToTop();
  const handleClick = () => {
    trackButtonClick({
      event: buttonEvents.redesEFranquias,
      buttonName: redesEFranquiasButtonNames.redesEFranquiasDepoimento,
    });

    scrollToTop();
  };

  return (
    <Container className={styles.container}>
      <span className={styles.highlightText}>Nossos clientes</span>
      <h2 className={styles.title}>
        Porque clínicas <span className={styles.title}>odontológicas</span> de todo o Brasil amam o
        Simples Dental
      </h2>
      <div className={styles.cardsContainer}>
        {feedbackConstants.map(f => (
          <Feedback feedback={f} key={f.id} />
        ))}
      </div>
      <Button variant="alternative-blue" onClick={() => handleClick()}>
        <span className={styles.text}>Solicite uma demonstração</span> (é grátis!)
      </Button>
    </Container>
  );
};

export default Feedbacks;

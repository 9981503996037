import React from 'react';
import clsx from 'clsx';
import * as styles from './hero.module.scss';
import star from '@/assets/images/star.svg';
import googlePlay from '@/assets/images/googleplay.png';
import FormLead from './Form/Form';
import { redesEFranquiasButtonNames } from '@/constants/analytics';

const Hero = () => {
  return (
    <div className={styles.container}>
      <div>
        <div>
          <div>
            <h1>
              <span className={clsx(styles.title, styles.traco)}>A melhor solução</span>
              <span className={styles.title}>para redes e franquias</span>
              <span className={styles.title}>odontológicas</span>
            </h1>
            <p className={styles.desc}>
              <span>Centralize os dados da sua operação</span>, otimize processos e descubra
              oportunidades de crescimento em tempo real.
            </p>
          </div>
          <hr className={styles.divider} />

          <div className={styles.card}>
            <img
              className={styles.googlePlay}
              src={googlePlay}
              alt="O melhor software de odontologia do mercado."
            />
            <div>
              <q>Fantástico. O melhor software de odontologia do mercado. Disparado!</q>
              <div>
                {[...Array(5)].map((element, index) => (
                  <img src={star} key={index} />
                ))}
                <span>5/5</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.form}>
        <FormLead
          gaMetadata={{
            buttonName: redesEFranquiasButtonNames.redesEFranquiasHero,
          }}
          shouldShowHeader={true}
        />
      </div>
    </div>
  );
};

export default Hero;
